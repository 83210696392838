* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  background: #161616;
  scroll-behavior: smooth;
}

body {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Zilla Slab", serif;
  animation: 3s show ease;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (min-width: 560px) {
  .container {
    max-width: 560px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1000px) {
  .container {
    max-width: 1000px;
  }
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: -webkit-fill-available;
}
.main-header {
  font-size: 36px;
  letter-spacing: 2px;
}
@media screen and (min-width: 560px) {
  .main-header {
    font-size: 44px;
  }
}
@media screen and (min-width: 768px) {
  .main-header {
    font-size: 60px;
  }
}
@media screen and (min-width: 1000px) {
  .main-header {
    font-size: 80px;
  }
}
.main-description {
  font-size: 18px;
  line-height: 40px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.6);
}
.main-description span {
  color: #42b883;
}
@media screen and (min-width: 560px) {
  .main-description {
    font-size: 24px;
  }
}
.main-list {
  list-style: none;
  margin-top: 100px;
}
.main-list__link {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  text-align: right;
  margin-bottom: 5px;
}
.main-list__link:last-child {
  margin-bottom: 0;
}
.main-list__link img {
  margin-left: 10px;
}
.main-list__link a {
  color: #ccc;
  transition: 0.3s;
}
.main-list__link a:hover {
  color: darkgray;
}
.main-list__link a:visited {
  color: darkcyan;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}